import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';

const PageWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }

  .page-header {
    margin-bottom: 2rem;
    flex: 1;
  }
  .main-content {
    flex: 4;
    margin-bottom: 4rem;
  }
`;

const NotFoundPage = () => (
  <PageWrapper>
    <SEO title="404: Not found" />
    <header className="page-header">
      <h1 className="page-title">404: Not Found</h1>
    </header>
    <section className="main-content">
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </section>
  </PageWrapper>
);

export default NotFoundPage;
